<template>
    <div class="dataB-catalogMange-con">
        <div class="nav-menu">
            <ul>
                <li
                    class="fl"
                    v-for="(item, index) in navData"
                    :key="`nav${item.Id}`"
                    :class="{'active': activeIndex === index}"
                    @click="changeTable(item.id||item.Id, index)"
                    :title="item.name"
                >
                    {{ item.name }}
                </li>
            </ul>
        </div>
        <div class="main">
            <component
                :is="zjName"
                ref="table"
                :entity-id="entityId"
            ></component>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        entityId: {
            type: String,
        },
    },
    data() {
        return {
            navData: [
                {
                    Id: '1001',
                    name: '核心元数据',
                    componentName: 'coreMetadata',
                },
                {
                    Id: '1002',
                    name: '信息项配置',
                    componentName: 'metadataEntityInfo',
                },
                {
                    Id: '1003',
                    name: '数据存储',
                    componentName: 'metadataEntityData',
                },
                {
                    Id: '1004',
                    name: '数据引用',
                    componentName: 'dataReference',
                },
                {
                    Id: '1005',
                    name: '数据浏览',
                    componentName: 'dataPreview',
                },
            ],
            activeIndex: 0,
            currentComponent: 'coreMetadata',
        };
    },
    watch: {},
    computed: {
        // 按类型动态加载表格
        zjName: function () {
            let t_url = '';
            let myComponent = null;
            if (this.currentComponent) {
                t_url = this.currentComponent;
                myComponent = () => import(`./${t_url}.vue`);
            }
            return myComponent;
        },
    },
    methods: {
        // 切换导航
        changeTable(id, index) {
            this.activeIndex = index;
            this.currentComponent = this.navData[index].componentName;
        },
    },
    created() {},
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.dataB-catalogMange-con
    float left
    width calc(100% - 2.3rem)
    height 100%
    background #fff
    margin-left .1rem
    .nav-menu
        height .54rem
        background #F8F8F8
        border-bottom 1px solid #D7D7D7
        box-sizing border-box
        position relative
        ul
            position absolute
            top 0
            left 0
            height .54rem
            li
                width 1.47rem
                float left
                height 100%
                position relative
                text-align center
                padding-left .2rem
                font-size .16rem
                line-height .54rem
                cursor pointer
                &:after
                    content ''
                    width .2rem
                    height .54rem
                    background url(./../images/businessGroup.png) no-repeat
                    background-size 100% 100%
                    position absolute
                    right -.2rem
                    top 0
            li.active
                background #6A7EBE
                color #fff
                &:before
                    content ''
                    width .2rem
                    height .54rem
                    background url(./../images/businessGroup.png) no-repeat
                    background-size 100% 100%
                    position absolute
                    left -1px
                    top 0
                &:after
                    content ''
                    width .2rem
                    height .54rem
                    background url(./../images/businessGroupActive.png) no-repeat
                    background-size 100% 100%
                    position absolute
                    right -.2rem
                    top 0
            :first-child.active
                &:before
                    content ''
                    width .2rem
                    height .54rem
                    background none
                    background-size 100% 100%
                    position absolute
                    left -1px
                    top 0
    .main
        height calc(100% - .54rem)
</style>